var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeadersMaterial,"items":_vm.offerMachines},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-autocomplete',{staticClass:"article-auto-complete",attrs:{"loading":_vm.isLoading,"items":_vm.articles,"search-input":_vm.searchText,"item-text":"artikelnr","hide-no-data":"","placeholder":"Artikel suchen","prepend-icon":"mdi-database-search","return-object":"","minlength":"2"},on:{"update:searchInput":function($event){_vm.searchText=$event},"update:search-input":function($event){_vm.searchText=$event}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.artikelNr)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(data.item.text)}}),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm._f("toCurrency")(data.item.preis)))])],1)]]}}]),model:{value:(_vm.selectedArticle),callback:function ($$v) {_vm.selectedArticle=$$v},expression:"selectedArticle"}}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Den Artikel aus der Materialtabelle "),_c('br'),_vm._v("löschen?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Abbrechen")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.pos",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.posRechner(index))+" ")]}},{key:"item.actuator",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.actuator)+" ")]}},{key:"item.kurztext",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"large":"","return-value":item.kurztext},on:{"update:returnValue":function($event){return _vm.$set(item, "kurztext", $event)},"update:return-value":function($event){return _vm.$set(item, "kurztext", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":""},model:{value:(item.kurztext),callback:function ($$v) {_vm.$set(item, "kurztext", $$v)},expression:"item.kurztext"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.kurztext)+" ")])]}},{key:"item.artikelNr",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.artikelNr)+" ")]}},{key:"item.stk",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.stk)+" ")]}},{key:"item.preisStk",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.preisStk))+" ")]}},{key:"item.preisPos",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.preisPos))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openKurztextDialog(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.openDeleteDialog(item)}}},[_vm._v(" mdi-delete ")])]}}])},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"black--text"},[_c('th',{staticClass:"title text-right",attrs:{"colspan":"7"}},[_vm._v("Gesamt")]),_c('th',{staticClass:"title text-right"},[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.sumField())))])])])],2),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.kurztextDialog),callback:function ($$v) {_vm.kurztextDialog=$$v},expression:"kurztextDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Kurztext ändern")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Kurztext"},model:{value:(_vm.editedItem.kurztext),callback:function ($$v) {_vm.$set(_vm.editedItem, "kurztext", $$v)},expression:"editedItem.kurztext"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeKurztextDialog}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.saveKurztextDialog}},[_vm._v(" Save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
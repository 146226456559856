import {Machine} from "@/models/machine";
import MaterialService from "@/views/offer/material.service.js";

export class OfferMachine {
    actuator: string;
    kurztext: string;
    artikelNr: number;
    stk: number;
    preisStk: number;
    preisPos: number;
    inspectionYears?: number;

    constructor(machine: Machine | any, stationName?: string) {

        if(machine instanceof Machine) {
            this.actuator = machine.actuator;
            this.kurztext = (stationName !== 'GASCADE Gastransport GmbH') ? MaterialService().getArticleText(machine) : MaterialService().getArticleTextGascade(machine);
            this.artikelNr = (stationName !== 'GASCADE Gastransport GmbH') ? MaterialService().getArticleNo(machine) : MaterialService().getArticleNoGascade(machine);
            this.stk = 1;
            this.preisStk = (stationName !== 'GASCADE Gastransport GmbH') ? MaterialService().getWartungspreis(machine) : MaterialService().getWartungspreisGascade(machine);
            this.preisPos = (stationName !== 'GASCADE Gastransport GmbH') ? MaterialService().getWartungspreis(machine) * 1 : MaterialService().getWartungspreisGascade(machine) * 1;
            this.inspectionYears = machine.inspectionYears;
        } else {
            this.actuator = '---';
            this.kurztext = machine.kurztext;
            this.artikelNr = machine.artikelNr;
            this.stk = 1;
            this.preisStk = machine.preisStk;
            this.preisPos = machine.preisStk * 1;
            this.inspectionYears = 0;
        }
    }
}










































































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import MaterialService from "@/views/offer/material.service.js";
import axios, { AxiosInstance } from 'axios';
import {OfferMachine} from "@/models/OfferMachine";
import {Material} from "@/models/Material";

@Component({
  components: {
  }
})
export default class MaterialTableComponent extends Vue {

  @Prop([])
  public stationMachines!: any[];

  @Prop()
  public customerName!: string;

  @Watch('angebotswertMaterial', {deep:true})
  public sum() {
    this.material.angebotswertMaterial = this.angebotswertMaterial;
  }

  @Watch('offerMachines', {deep:true})
  public updateOfferMachines() {
    this.$emit('offerMachines', this.material)

  }

  public offerMachines: OfferMachine[] = [];
  public material: Material = {};

  public created() {
    this.offerMachines = [];
    this.stationMachines.forEach(machine => this.offerMachines.push(new OfferMachine(machine, this.customerName)))
    //console.log(this.offerMachines)
    const groupedOfferMachines = this.regroup(this.offerMachines)

    //console.log('groupedOfferMachines', groupedOfferMachines)

    const amountEachArticleNo = Object.keys(groupedOfferMachines).map(key => groupedOfferMachines[key].length);
    // console.log('amountEachArticleNo', amountEachArticleNo)
    const actuatorOfEachArticleNo = Object.values(groupedOfferMachines).map((val: any) => val[0]);
    // console.log('actuatorOfEachArticleNo', actuatorOfEachArticleNo)
    const newOfferMachines = actuatorOfEachArticleNo.map((actuator, index) => {
      actuator.stk = amountEachArticleNo[index];
      actuator.preisPos = actuator.preisPos * amountEachArticleNo[index];
        return actuator
    })
    console.log('newOfferMachines', newOfferMachines)


    this.offerMachines = newOfferMachines;

    this.material = new Material({offerMachines: this.offerMachines, angebotswertMaterial: this.angebotswertMaterial})
  }

  public regroup(arg: any) {
    return arg.reduce((group: any, product: any) => {
      const { artikelNr } = product;
      group[artikelNr] = group[artikelNr] ?? [];
      group[artikelNr].push(product);
      return group;
    }, {});
  }

  protected baseURL: string = process.env.VUE_APP_BASE_URL!;
  protected axiosClient: AxiosInstance = axios.create({
    baseURL: this.baseURL,
    timeout: 1060000
  });

  public angebotswertMaterial: number = 0;

  public pos: number = 1;


  public kurztextDialog = false;
  public dialogDelete = false;

  public editedIndex = -1;

  public editedItem = {
    actuator: '',
    kurztext: '',
    artikelNr: 0,
    stk: 0,
    preisStk: 0,
    preisPos: 0,
  }

  public defaultItem = {
    actuator: '',
    kurztext: '',
    artikelNr: 0,
    stk: 0,
    preisStk: 0,
    preisPos: 0,
  };

  public tableHeadersMaterial = [
    {
      text: 'Pos.',
      value: 'pos',
      width: '5%',
      align: 'center',
      sortable: false
    },
    {
      text: 'Benennung',
      value: 'actuator',
      width: '10%',
      align: 'start',
      sortable: false
    },
    {
      text: 'Artikelbezeichnung (Kurztext)',
      value: 'kurztext',
      width: '25%',
      align: 'start',
      sortable: false
    },
    {
      text: 'Artikel-Nr.',
      value: 'artikelNr',
      width: '10%',
      align: 'start',
      sortable: false
    },
    {
      text: 'Stk.',
      value: 'stk',
      width: '5%',
      align: 'center',
      sortable: false
    },
    {
      text: 'Preis/Stk..',
      value: 'preisStk',
      width: '10%',
      align: 'start',
      sortable: false
    },
    {
      text: 'Preis/Pos.',
      value: 'preisPos',
      width: '10%',
      align: 'start',
      sortable: false
    },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      width: '5%',
      sortable: false
    }
  ];

  public articles: any = [];
  public selectedArticle: any | null = null;
  public isLoading: boolean = false;

  public searchText: string = '';

  @Watch('searchText', { immediate: true })
  public async searchArticle(searchVal: string) {
    this.isLoading = true;

    if (searchVal && searchVal.length > 4) {
      let res = await this.axiosClient.get('article/' + searchVal);
      this.articles = res.data;
    }
    this.isLoading = false;
  }

  @Watch('selectedArticle', { immediate: true })
  public async selectedArticleChanged() {
    if (this.selectedArticle) {
      var finalObject = new OfferMachine({
        artikelNr: this.selectedArticle.artikelnr,
        kurztext: this.selectedArticle.text,
        preisStk: this.selectedArticle.preis
      })
      this.offerMachines.push(finalObject)
    }
    this.searchText = '';
    this.articles = [];
  }

  public openKurztextDialog(item: any) {
    this.editedIndex = this.offerMachines.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.kurztextDialog = true;
  }

  closeKurztextDialog() {
    this.editedItem = Object.assign({}, this.defaultItem)
    this.editedIndex = -1
    this.kurztextDialog = false
  }

  saveKurztextDialog() {

    if (this.editedIndex > -1) {
      Object.assign(this.offerMachines[this.editedIndex], this.editedItem)
    } else {
      this.offerMachines.push(this.editedItem)
    }

    this.closeKurztextDialog()
  }

  openDeleteDialog (item: any) {
    this.editedIndex = this.offerMachines.indexOf(item)
    this.dialogDelete = true
  }

  deleteItemConfirm () {
    this.offerMachines.splice(this.editedIndex, 1)
    this.closeDelete()
  }

  closeDelete () {
    this.dialogDelete = false
    this.$nextTick(() => {
      this.editedIndex = -1
    })
  }

  public posRechner(index: any) {
    let m = (index === 0) ? 0.1 : (index+1)/10;
    return this.pos + m
  }

  public sumField(): number {
    let sum: number = 0;

    this.offerMachines.forEach((machine: OfferMachine) => {
      sum += machine.preisPos;
    });

    this.angebotswertMaterial = sum;
    return sum;
  }

  public getPriceWithFaktor(item: OfferMachine) {
    return MaterialService().getPriceWithFactor(item)
  }



}
